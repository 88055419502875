import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';

import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-email-modal',
  styleUrls: ['./account-email-modal.component.scss'],
  templateUrl: './account-email-modal.component.html'
})
export class AccountEmailModalComponent implements OnDestroy {
  customerDetailsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private store: Store<State>,
    private trackingService: TrackingService
  ) {
    this.customerDetailsForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  ngOnDestroy() {
    this.customerDetailsForm.reset();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });

    this.modalController.dismiss(undefined, undefined, 'password-modal');
  }

  async updateEmail() {
    if (this.customerDetailsForm.invalid) {
      return this.customerDetailsForm.markAllAsTouched();
    }
    const loading = await this.loadingController.create();
    await loading.present();
    this.trackingService.trackActivity('[Button] Save updated email');
    this.store.dispatch(AccountActions.updateEmail({ data: this.customerDetailsForm.getRawValue() }));
  }
}
