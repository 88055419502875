import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Customer } from '@inyova/models';
import { ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { map, noop, Observable } from 'rxjs';
import * as fromAccount from '@account/account.reducers';

import { LocalStorageKeys } from '@app/app.constants';
import { TrackingService } from '@shared/services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-banner-modal',
  templateUrl: './home-banner-modal.component.html',
  styleUrls: ['./home-banner-modal.component.scss']
})
export class HomeBannerModalComponent implements OnInit {
  @Input() appLocation = 'ch';
  @Input() language: string;
  @Input() modalType: string;
  @Input() externalPage: string;
  links = {
    en: 'https://inyova.ch/en/expertise/investing-money-for-kids/',
    de: 'https://inyova.ch/wissen/geld-fuer-kinder-anlegen/',
    fr: 'https://inyova.ch/fr/expertise/investir-pour-ses-enfants/'
  };

  articleLink: string;
  listItems$: Observable<string[]>;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private store: Store,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.listItems$ = this.store.select(fromAccount.selectCustomer).pipe(map((customer) => this.generateListItems(customer)));
    this.articleLink = this.links[this.language.split('-')[0]];
  }

  close(): void {
    this.trackingService.trackActivity(`[Button]: Closed Modal ${this.modalType}`);
    this.dismiss();
  }

  dismiss(): void {
    void this.modalController
      .dismiss({
        dismissed: true
      })
      .finally(() => {
        this.trackingService.trackActivity('[Modal]: Closed Modal');
      });
  }

  generateListItems(customer: Customer): string[] {
    const listMap: { [key: string]: string[] } = {
      i4k: ['MODALS.i4k.list.1', ...(customer.hide_personalisation ? [] : ['MODALS.i4k.list.2']), 'MODALS.i4k.list.3'],
      '3a': [...(customer.hide_personalisation ? [] : ['MODALS.3a.list.1']), 'MODALS.3a.list.2', 'MODALS.3a.list.3'],
      '3b': ['MODALS.3b.list.1', 'MODALS.3b.list.2', ...(customer.hide_personalisation ? [] : ['MODALS.3b.list.3'])]
    };
    return listMap[this.modalType] || [];
  }

  openBannerLink() {
    this.trackingService.trackActivity(`[Button]: Open ${this.modalType} account - CTA`);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${environment.customersApp[this.appLocation]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=${this.externalPage}`;

    const browser = Browser.open({ url });

    void Browser.addListener('browserFinished', () => {
      this.router.navigateByUrl('/public/login').finally(noop);
    });

    this.dismiss();
    return browser;
  }
}
