// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.ds-scale {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border-1) solid var(--color-core-neutral-4);
  border-radius: var(--radius-2);
  width: 100%;
  overflow: hidden;
}
.ds-scale-item {
  cursor: pointer;
  max-width: 320px;
  height: 32px;
  flex: 1;
  display: flex;
  border-right: var(--border-1) solid var(--color-core-neutral-4);
}
.ds-scale-item:last-child {
  border-right: medium none;
}
.ds-scale-item.selected {
  background-color: var(--color-core-neutral-10);
}
.ds-scale-item.selected > span {
  color: var(--color-core-neutral-0);
}
.ds-scale-item > span {
  color: var(--color-core-neutral-7);
  margin: auto;
}
.ds-scale-labels {
  margin-top: var(--spacing-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
