import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { ResetPasswordComponent } from '@public/modals';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-change-password',
  styleUrls: ['./account-change-password.component.scss'],
  templateUrl: './account-change-password.component.html'
})
export class AccountChangePasswordComponent {
  minLength = 12;
  maxLength = 128;
  changePasswordForm: FormGroup;
  currentPassVisible = false;
  newPassVisible = false;
  repeatPassVisible = false;
  score: number;

  constructor(
    private fb: FormBuilder,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private store: Store<State>,
    private trackingService: TrackingService,
    private statusBarStyling: StatusBarStylingService
  ) {
    this.changePasswordForm = this.fb.group({
      passwordCurrent: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)]]
    });
  }

  get passwordControl() {
    return this.changePasswordForm.get('password');
  }

  get passwordNumbers() {
    if (!this.passwordControl.value) {
      return false;
    }
    return /\d/.test(this.passwordControl.value);
  }

  get passwordUppercaseLetters() {
    if (!this.passwordControl.value) {
      return false;
    }
    return /[A-Z]/.test(this.passwordControl.value);
  }

  get passwordLowercaseLetters() {
    if (!this.passwordControl.value) {
      return false;
    }
    return /[a-z]/.test(this.passwordControl.value);
  }

  get passDontHaveIdenticalChars() {
    if (!this.passwordControl.value) {
      return false;
    }
    return /^(?!.*(.)\1\1)/.test(this.passwordControl.value);
  }

  get passwordInvalid() {
    return !(
      this.validatePasswordLength(this.passwordControl.value) &&
      this.passwordNumbers &&
      this.passwordUppercaseLetters &&
      this.passwordLowercaseLetters &&
      this.passDontHaveIdenticalChars
    );
  }

  get progressbarColor() {
    if (this.passwordControl.invalid && this.passwordControl.touched) {
      return 'danger';
    }
    if (!this.passwordControl.value) {
      return 'primary';
    }
    if (this.score > 60) {
      return 'success';
    } else if (this.score > 30) {
      return 'warning';
    }
    return 'danger';
  }

  async changePassword() {
    if (this.changePasswordForm.invalid || this.passwordInvalid) {
      this.changePasswordForm.markAllAsTouched();
      return;
    }
    this.trackingService.trackActivity('[Button] Save new password');
    const loading = await this.loadingController.create({});
    await loading.present();
    this.store.dispatch(AccountActions.changePassword(this.changePasswordForm.getRawValue()));
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  getScore() {
    if (this.passwordControl.value) {
      return this.score / 100 > 0.2 ? this.score / 100 : 0.2;
    }
    return 1;
  }

  hasError(control: string, error: string) {
    if (!this.changePasswordForm.get(control)) {
      return false;
    }
    return (
      this.changePasswordForm.get(control).hasError(error) &&
      (this.changePasswordForm.get(control).touched || this.changePasswordForm.get(control).dirty)
    );
  }

  newPassChange() {
    this.score = this.scorePassword(this.passwordControl.value);
  }

  private scorePassword(pass: string): number {
    let score = 0;

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      len: /(?=.{12,128}$)/.test(pass),
      digits: /(?=.*\d)/.test(pass),
      nonWords: /(?=.*[[:^alnum:]])/.test(pass)
    };

    let variationCount = 0;

    for (const check of Object.keys(variations)) {
      variationCount += variations[check] === true ? 2 : -1;
    }
    score += (variationCount - 1) * 10;

    if (this.passwordInvalid) {
      score = 10;
    }

    return score;
  }

  validatePasswordLength(pass: string): boolean {
    if (!pass.length) {
      return false;
    }
    const passwordRegx = new RegExp('^(?=.{12,128}$)');
    return passwordRegx.test(pass);
  }

  async openForgotPassword() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: ResetPasswordComponent,
      cssClass: 'app-fullscreen',
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('dialog');
    });
    return await modal.present();
  }
}
