// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

.ds-select {
  position: relative;
  width: 100%;
}
.ds-select-chevron {
  position: absolute;
  top: 50%;
  right: var(--spacing-3);
  transform: translateY(-50%);
}
.ds-select select {
  box-sizing: border-box;
  display: block;
  padding-right: var(--spacing-7);
  width: 100%;
}
.ds-select select.default {
  color: var(--color-core-neutral-5);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
