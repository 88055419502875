/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRemainingTime } from '@inyova/inyova-shared';
import { isCustomer } from '@inyova/utils';
import { IonicSlides } from '@ionic/angular';
import { cloneDeep } from 'lodash-es';
import { Swiper } from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as StrategyActions from '@strategy/strategy.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { IMPACT_CAMPAIGNS_INYOVA_IDS } from '@app/app.constants';
import { ImpactMetricItem, ImpactStoriesItem, ImpactStoryDetail } from '@shared/models/Home';
import { TrackingService } from '@shared/services/tracking.service';

// install Swiper modules
Swiper.use([Pagination, Navigation, Scrollbar]);

@Component({
  selector: 'app-home-impact',
  styleUrls: ['./home-impact.component.scss'],
  templateUrl: './home-impact.component.html'
})
export class HomeImpactComponent implements OnDestroy, OnInit {
  impactMetrics: ImpactMetricItem[] = [];
  isMetricLoading$: Observable<boolean>;
  isCrowdInvestor = true;
  isOnlyCrowdinvestor = false;
  isOnlyGrow = false;
  isInitialized: boolean;
  campaigns: ImpactStoriesItem[] = [];
  yovaYellow: boolean;
  language: string;

  slidesPerView = 1.2;

  isItPublicis: boolean;

  IMPACT_CAMPAIGNS_INYOVA_IDS = IMPACT_CAMPAIGNS_INYOVA_IDS;

  swiperModules = [IonicSlides];

  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((customer) => {
      this.isCrowdInvestor = customer.has_crowd_investor_account;
      this.language = customer.language;
      const customerAccount = customer.accounts.filter((account) => isCustomer(account.step_status));
      this.isOnlyCrowdinvestor = !customerAccount.length && this.isCrowdInvestor;

      const hasGrow = customer.has_interest_account;
      this.isOnlyGrow = !customerAccount.length && hasGrow;
    });
    this.store.pipe(select(fromAccount.selectCurrentAccount), takeUntil(this.onDestroy$)).subscribe((currentAccount) => {
      this.yovaYellow = currentAccount.is_yova_yellow;
      if (this.yovaYellow) {
        this.store.dispatch(StrategyActions.getCustomerStocks());
      }
    });
    this.store.pipe(select(fromHome.selectImpactMetrics), takeUntil(this.onDestroy$)).subscribe((metrics) => {
      this.impactMetrics = metrics;
    });
    this.store.pipe(select(fromHome.selectImpactInitialized), takeUntil(this.onDestroy$)).subscribe((isInitialized) => {
      this.isInitialized = isInitialized;
    });

    this.isMetricLoading$ = this.store.select(fromHome.selectMetricsLoading);

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      if (!params.impactMetric || !this.impactMetrics || this.impactMetrics.length === 1) {
        return;
      }
      const metrics = [...this.impactMetrics];
      // Sort the metrics by Random metrics from Insights
      const index = this.impactMetrics.findIndex((item) => item.name === params.impactMetric);
      metrics.splice(index, 1);
      metrics.unshift(this.impactMetrics[index]);
      this.impactMetrics = metrics;
      // Clear queryParams
      void this.router.navigateByUrl('/tabs/home/impact');
    });

    this.store.pipe(select(fromHome.selectEngagementStories), takeUntil(this.onDestroy$)).subscribe((campaigns) => {
      if (!campaigns?.length) {
        return;
      }
      this.campaigns = cloneDeep(campaigns);
      this.campaigns.forEach((campaign) => {
        // modified object according to expries date
        // eslint-disable-next-line no-param-reassign
        campaign.attributes.expired = new Date(campaign.attributes.expires_at) < new Date();
        campaign.attributes.remaining = getRemainingTime(campaign.attributes.expires_at);
        campaign.attributes.isPublicis = IMPACT_CAMPAIGNS_INYOVA_IDS.includes(Number(campaign.attributes.inyova_id ?? 0));
      });

      // update slider settings
      if (this.campaigns.length === 1) {
        this.slidesPerView = 1;
      }
    });
  }

  ngOnInit() {
    if (!this.isInitialized) {
      this.store.dispatch(HomeActions.getImpactMetrics());
    }
  }

  isExpired(campaign) {
    return new Date(campaign?.attributes?.expires_at) < new Date();
  }

  track(event: string): void {
    this.trackingService.trackActivity(event);
  }

  openCampaignDetail(campaign: ImpactStoriesItem): void {
    this.track(`[Impact] Open campaign detail: ${campaign.attributes.title}`);
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: campaign as ImpactStoryDetail }));

    if (IMPACT_CAMPAIGNS_INYOVA_IDS.includes(Number(campaign.attributes.inyova_id))) {
      void this.router.navigate([`/tabs/home/campaign/${campaign.id}`]);
    } else {
      void this.router.navigate(['/tabs/home/engagement', campaign.id]);
    }
  }

  goToGrowProjects() {
    void this.router.navigate(['/tabs/grow-performance']);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
