// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  text-align: center;
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
}

ion-item {
  --background-focused: transparent;
  --background: transparent;
  --padding-start: 0;
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-primary);
  --border-color: var(--ion-color-primary);
  --border-width: 0 0 2px 0;
  margin-top: 30px;
}
ion-item.ion-touched {
  --highlight-color-invalid: var(--ion-color-danger);
}

ion-button {
  font-weight: bold;
  margin: 0;
  width: 100%;
}

ion-button.close {
  margin-top: 30px;
}

.ion-input__errors {
  margin-bottom: 50px;
}

.promotion-details p {
  font-size: 14px;
}
.promotion-details p.info {
  font-weight: 700;
  text-align: left;
}
.promotion-details li {
  font-weight: 700;
  font-size: 14px;
}
.promotion-details ul {
  margin: 0;
  text-align: left;
  margin-bottom: 4px;
  padding-left: 30px;
}
.promotion-details ion-button {
  margin-top: 24px;
}

.promocode-banner {
  display: flex;
  align-items: center;
  background-color: var(--ion-color-success-background);
  border-radius: 8px;
  padding: 12px 14px;
  margin: 24px 0;
}
.promocode-banner ion-icon {
  color: var(--ion-color-success);
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.promocode-banner .promocode-banner__code {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}
.promocode-banner .promocode-banner__date {
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: var(--ion-color-tertiary);
}

.notification-3a {
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 12px 14px;
  margin-top: 24px;
  background-color: var(--ion-color-light-grey);
}
.notification-3a ion-icon {
  color: var(--ion-color-tertiary);
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 12px;
}
.notification-3a p {
  text-align: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
