export enum WithdrawalRequestStatus {
  SUBMITTED = 'Submitted',
  IN_PROGRESS = 'In progress',
  CANCELED = 'Canceled'
}

export enum WithdrawType {
  PARTIAL = 'PartialWithdrawalRequest',
  SAFE = 'SafeWithdrawalRequest',
  FULL = 'FullWithdrawalRequest'
}

export enum WithdrawalSteps {
  INITIAL = 'initial',
  UPDATE_NOTIFICATION = 'update_notification',
  IMPACT_NOTIFICATION = 'impact_notification',
  PROMO_NOTIFICATION = 'promo_notification',
  IBAN = 'iban',
  REASONS = 'reasons',
  CLOSE = 'close',
  SUMMARY = 'summary',
  STATUS = 'status'
}

export interface WithdrawalForm {
  withdrawal: {
    type: WithdrawType;
    amount: number;
  };
  iban: {
    type: 'account' | 'new';
    iban: string;
    newIban: string;
  };
  extra: {
    reasons: number[];
    keepAccount: boolean;
  };
}

export interface WithdrawalNotificationContent {
  key: string;
  iconVariant: 'info' | 'warning' | 'success';
  topics: { icon: string; key: string }[];
}

export interface WithdrawalReasons {
  id: number;
  inyova_id: number;
  question_text: string;
  survey_answer_options: WithdrawalReasonOption[];
}

export interface WithdrawalReasonOption {
  details: 'external' | 'internal';
  id: number;
  inyova_id: number;
  option_text: string;
}

export type WithdrawalStatus = 'success' | 'fail' | 'processing' | 'canceled' | 'promotion';

export interface WithdrawRequest {
  type: string;
  id?: number | string;
  attributes: {
    type: WithdrawType;
    iban: string;
    close_account: boolean | null;
    amount_to_deduct: number;
    new_reference_account_requested: boolean;
    account_id: string;
    status?: WithdrawalRequestStatus;
    survey_answers?: {
      question_id: number;
      answer: number[];
    };
  };
}

export interface WithdrawalRequest {
  type: string;
  attributes: {
    account_id: string;
    iban: string;
    amount_to_deduct: number;
    liquidation: boolean;
    close_account: boolean;
    full_withdrawal: boolean;
  };
}
