import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-account-greenness-notification',
  templateUrl: './account-greenness-notification.component.html',
  styleUrls: ['./account-greenness-notification.component.scss']
})
export class AccountGreennessNotificationComponent {
  @Input() webApp = false;
  @Input() success: boolean;

  @Output() closeNotification: EventEmitter<void> = new EventEmitter<void>();
}
