// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  text-align: center;
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
}

.fees-list {
  margin-top: 30px;
}
.fees-list .disclaimer-3a {
  font-size: 14px;
  text-align: left;
  margin-bottom: 8px;
}
.fees-list .disclaimer-3a:first-of-type {
  margin-top: 12px;
}
.fees-list .disclaimer-3a:last-of-type {
  margin-bottom: 32px;
}

.fees-list__item {
  align-items: center;
  background: var(--ion-color-white);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 15px;
}
.fees-list__item--active {
  background: var(--ion-color-secondary);
}
.fees-list__item p {
  flex: 2;
  font-size: 16px;
  font-weight: 500;
}
.fees-list__item p:first-of-type {
  flex: 1;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-align: left;
}
.fees-list__item--3a {
  background: var(--ion-color-white);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 15px;
}
.fees-list__item--3a p {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}
.fees-list__item--3a p:first-of-type {
  width: 60%;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
