import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ds-scale',
  templateUrl: './ds-scale.component.html',
  styleUrls: ['./ds-scale.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsScaleComponent),
      multi: true
    }
  ]
})
export class DsScaleComponent implements OnInit, ControlValueAccessor {
  @Input() numberOfItems: number;
  @Input() showLabels = false;
  value: number;
  items: any[];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: number) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  ngOnInit() {
    this.items = Array.from({ length: this.numberOfItems }, (v, i) => i + 1);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: number): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }
}
