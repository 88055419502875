import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from '@inyova/models';
import { LoadingController, ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as fromAccount from '@account/account.reducers';

import { State } from '@shared/models/State';

@Component({
  selector: 'app-account-verify-phone-number',
  styleUrls: ['./account-verify-phone-number.component.scss'],
  templateUrl: './account-verify-phone-number.component.html'
})
export class AccountVerifyPhoneNumberComponent implements OnInit, OnDestroy {
  confirmForm: FormGroup;
  customer: Customer;
  code: string;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private router: Router,
    private store: Store<State>,
    private loadingController: LoadingController
  ) {
    this.confirmForm = this.fb.group({
      code: ['', [Validators.required]]
    });
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.customer = value;
    });
  }

  async otpCodeUpdate(code: string): Promise<void> {
    if (code && code.length === 6) {
      const loading = await this.loadingController.create({});
      await loading.present();
      // Reset opt input after each try
      loading
        .onDidDismiss()
        .then(() => {
          this.code = `${Math.random()}`;
        })
        .catch(noop);
      this.store.dispatch(AccountActions.verifyPhoneNumber({ code }));
    }
  }

  redirectToSupport(): void {
    this.dismiss();
    this.router.navigateByUrl('/tabs/account/support').finally(noop);
  }

  ngOnInit(): void {
    this.sendCode();
  }

  ngOnDestroy(): void {
    this.confirmForm.reset();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  dismiss(): void {
    this.modalController.dismiss({ dismissed: true }).finally(noop);
    this.modalController.dismiss(undefined, undefined, 'phone-modal').finally(noop);
    this.modalController.dismiss(undefined, undefined, 'password-modal').finally(noop);
  }

  sendCode(): void {
    this.store.dispatch(AccountActions.sendVerifyPhoneCode());
  }
}
