// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 15px;
  --padding-end: 15px;
  color: var(--ion-color-primary);
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  text-align: center;
}

.content-headline {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--font-secondary);
  margin-top: 30px;
  margin-bottom: 10px;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}
.step span {
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  color: var(--ion-color-white);
  font-size: 10px;
  font-weight: 700;
  height: 15px;
  text-align: center;
  margin-right: 10px;
  width: 15px;
  line-height: 17px;
}
.step p {
  margin-top: -2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
