import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as fromAccount from '@account/account.reducers';

import { State } from '@shared/models/State';

@Component({
  selector: 'app-account-fees-promocode',
  styleUrls: ['./account-fees-promocode.component.scss'],
  templateUrl: './account-fees-promocode.component.html'
})
export class AccountFeesPromocodeComponent implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  hasOnly3AAccount: boolean;
  redeemPromocodeForm: FormGroup;
  promotions: {
    code: string;
    activatedAt: string;
    freeMonths: number;
    moneyPrmotion: {
      amount: number;
      period: number;
    };
  };

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private store: Store<State>
  ) {
    this.redeemPromocodeForm = this.fb.group({
      code: ['', Validators.required]
    });
    this.store.pipe(select(fromAccount.selectPromotionsDataForRedeemCode), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.promotions = value;
    });
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.hasOnly3AAccount = value.accounts?.length === 1 && value.accounts[0]?.kind === '3a';
    });
  }

  ngOnDestroy(): void {
    this.redeemPromocodeForm.reset();
  }

  dismiss(): void {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  redeemPromocode(): void {
    if (this.redeemPromocodeForm.invalid) {
      this.redeemPromocodeForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(AccountActions.redeemPromocode(this.redeemPromocodeForm.getRawValue()));
  }
}
