import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule } from '@inyova/design-system';
import { TranslateModule } from '@ngx-translate/core';

import * as fromComponents from './components';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DesignSystemModule, TranslateModule],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components]
})
export class AccountCommonModule {}
