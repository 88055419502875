import { LucideIconData, LucideIcons } from 'lucide-angular/icons/types';
import { parseSync } from 'svgson';

function svgToLucideIconData(svgString: string): LucideIconData {
  const parsed = parseSync(svgString);
  return parsed.children.map((n: any) => [n.name, n.attributes]);
}

function getCustomIconList(): LucideIcons {
  return {
    // this is the icon name! must be uppercase!
    Inyova: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 11.9871V3H3C3 7.96352 7.0336 12 11.9936 12H3V21C7.9664 21 12 16.9699 12 12H21V3C16.04 3 12.0065 7.03004 12 11.9871Z" />
      </svg>
    `),
    Renewable: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.516 3.00947 16.931 3.99122 18.74 5.74L21 8"/>
        <path d="M12 7L9 12H14.5L11.5 17"/>
        <path d="M21 3V8H16"/>
        <path d="M21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21C9.48395 20.9905 7.06897 20.0088 5.26 18.26L3 16"/>
        <path d="M8 16H3V21"/>
      </svg>
    `),
    NoCO2: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L22 22" />
        <path d="M20 10V14.5" />
        <path d="M16 10V11" />
        <path d="M16 10H20" />
        <path d="M16 7C16 4.5 14 4.5 14 2" />
        <path d="M20 7C20 4.5 18 4.5 18 2" />
        <path d="M20 20.5103C20 20.9054 19.8525 21.2843 19.5899 21.5637C19.3274 21.8431 18.9713 22 18.6 22H3.5C3.1287 22 2.7726 21.8431 2.51005 21.5637C2.2475 21.2843 2.1 20.9054 2.1 20.5103V12L7 15V12L12.5 15.5V13" />
        <path d="M11 18H12" />
        <path d="M6 18H7" />
      </svg>
    `),
    DsEdit: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `),
    ChevronUp: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 15L12 9L6 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    ChevronDown: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9L12 15L18 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    WalletCards: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 11H6C6.8 11 7.6 11.3 8.1 11.9L9.2 12.8C10.8 14.4 13.3 14.4 14.9 12.8L16 11.9C16.5 11.4 17.3 11 18.1 11H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
   `),
    CircleCheckBig: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 11L12 14L22 4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
   `),
    TreeDeciduous: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00017 19C7.14217 19.0019 6.30629 18.7279 5.61594 18.2184C4.92559 17.7089 4.41736 16.9909 4.16629 16.1705C3.91523 15.35 3.93463 14.4706 4.22163 13.662C4.50863 12.8534 5.04803 12.1586 5.76017 11.68C5.3211 11.1314 5.05733 10.4633 5.00319 9.76273C4.94904 9.06214 5.10702 8.36148 5.45658 7.75191C5.80613 7.14235 6.33107 6.65212 6.96309 6.34501C7.5951 6.0379 8.30492 5.92813 9.00017 6.03V6C9.00017 5.20435 9.31624 4.44129 9.87885 3.87868C10.4415 3.31607 11.2045 3 12.0002 3C12.7958 3 13.5589 3.31607 14.1215 3.87868C14.6841 4.44129 15.0002 5.20435 15.0002 6V6.04C15.6954 5.93813 16.4052 6.0479 17.0373 6.35501C17.6693 6.66212 18.1942 7.15235 18.5438 7.76191C18.8933 8.37148 19.0513 9.07214 18.9972 9.77273C18.943 10.4733 18.6792 11.1414 18.2402 11.69C18.9479 12.1702 19.483 12.8645 19.7671 13.6712C20.0512 14.4778 20.0693 15.3543 19.8188 16.1719C19.5682 16.9896 19.0622 17.7055 18.3749 18.2144C17.6877 18.7234 16.8554 18.9987 16.0002 19H8.00017Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 19V22" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    BarChart3: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3V21H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18 17V9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 17V5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 17V14" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    Leaf: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0002 20C9.24428 20.0053 7.55048 19.3505 6.25474 18.1654C4.959 16.9803 4.15599 15.3515 4.00496 13.6021C3.85393 11.8527 4.36591 10.1104 5.43937 8.72074C6.51283 7.33112 8.06935 6.3957 9.80022 6.1C15.5002 5 17.0002 4.48 19.0002 2C20.0002 4 21.0002 6.18 21.0002 10C21.0002 15.5 16.2202 20 11.0002 20Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 21C2 18 3.85 15.64 7.08 15C9.5 14.52 12 13 13 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    ECar: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 17H21C21.6 17 22 16.6 22 16V13C22 12.1 21.3 11.3 20.5 11.1C18.7 10.6 16 10 16 10C16 10 15.5 9.5 13.5998 7.5M5 8.5L2.2 11.3C2 11.5 2 12.0917 2 12.5V16C2 16.6 2.4 17 3 17H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 17H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.53549 4.12124L9.36392 6.94967L10.7781 5.53546" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.364 6.94975C9.55153 7.13728 9.65689 7.39164 9.65689 7.65685C9.65689 7.92207 9.55153 8.17642 9.364 8.36396L8.65689 9.07107C8.28182 9.44614 7.77311 9.65685 7.24268 9.65685C6.71224 9.65685 6.20354 9.44614 5.82846 9.07107L4.41425 7.65685C4.03918 7.28178 3.82846 6.77307 3.82846 6.24264C3.82846 5.71221 4.03918 5.2035 4.41425 4.82843L5.12136 4.12132C5.30889 3.93378 5.56325 3.82843 5.82846 3.82843C6.09368 3.82843 6.34803 3.93378 6.53557 4.12132L7.94978 2.70711" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    Megaphone: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 11L21 6V18L3 14V11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.6002 16.8002C11.4952 17.181 11.3161 17.5374 11.0733 17.8491C10.8305 18.1607 10.5287 18.4215 10.1851 18.6165C9.84156 18.8115 9.46294 18.9369 9.0709 18.9856C8.67885 19.0343 8.28105 19.0053 7.90022 18.9002C7.51938 18.7951 7.16297 18.6161 6.85133 18.3733C6.53969 18.1305 6.27892 17.8287 6.08392 17.4851C5.88892 17.1415 5.7635 16.7629 5.71482 16.3709C5.66614 15.9788 5.69516 15.581 5.80022 15.2002" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `),
    HandHeart: svgToLucideIconData(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 14H13C13.5304 14 14.0391 13.7893 14.4142 13.4142C14.7893 13.0391 15 12.5304 15 12C15 11.4696 14.7893 10.9609 14.4142 10.5858C14.0391 10.2107 13.5304 10 13 10H10C9.4 10 8.9 10.2 8.6 10.6L3 16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 20.0003L8.6 18.6003C8.9 18.2003 9.4 18.0003 10 18.0003H14C15.1 18.0003 16.1 17.6003 16.8 16.8003L21.4 12.4003C21.7859 12.0356 22.0111 11.5326 22.0261 11.0018C22.0411 10.4711 21.8447 9.95616 21.48 9.57027C21.1153 9.18438 20.6123 8.95916 20.0816 8.94416C19.5508 8.92916 19.0359 9.1256 18.65 9.49027L14.45 13.3903" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 15L8 21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.5001 8.5C20.2001 7.8 21.0001 6.9 21.0001 5.8C21.07 5.18893 20.9315 4.57216 20.6071 4.04964C20.2826 3.52712 19.7912 3.12947 19.2125 2.92114C18.6338 2.71281 18.0017 2.706 17.4187 2.90182C16.8356 3.09763 16.3358 3.4846 16.0001 4C15.6432 3.52458 15.1432 3.17613 14.5736 3.00578C14.004 2.83544 13.3948 2.85219 12.8354 3.05356C12.2761 3.25494 11.796 3.63034 11.4657 4.12465C11.1353 4.61896 10.9722 5.20614 11.0001 5.8C11.0001 7 11.8001 7.8 12.5001 8.6L16.0001 12L19.5001 8.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `),
    PartyPopper: svgToLucideIconData(`
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8 11.2998L2 21.9998L12.7 18.2098" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 3H4.01" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 8H22.01" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 2H15.01" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 20H22.01" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 2L19.76 2.75C19.1224 2.96239 18.5783 3.38964 18.2208 3.95872C17.8633 4.52781 17.7146 5.20339 17.8 5.87C17.9 6.73 17.23 7.5 16.35 7.5H15.97C15.11 7.5 14.37 8.1 14.21 8.94L14 10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 12.9999L21.18 12.6699C20.32 12.3299 19.36 12.8699 19.2 13.7799C19.09 14.4799 18.48 14.9999 17.77 14.9999H17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 2L11.33 2.82C11.67 3.68 11.13 4.64 10.22 4.8C9.52 4.9 9 5.52 9 6.23V7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 12.9995C12.93 14.9295 13.83 17.1695 13 17.9995C12.17 18.8295 9.93002 17.9295 8.00002 15.9995C6.07002 14.0695 5.17002 11.8295 6.00002 10.9995C6.83002 10.1695 9.07002 11.0695 11 12.9995Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `),
    Sparkles: svgToLucideIconData(`
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.93694 15.5002C9.84766 15.1542 9.66728 14.8384 9.41456 14.5856C9.16184 14.3329 8.84601 14.1525 8.49994 14.0632L2.36494 12.4812C2.26027 12.4515 2.16815 12.3885 2.10255 12.3017C2.03696 12.2149 2.00146 12.1091 2.00146 12.0002C2.00146 11.8914 2.03696 11.7856 2.10255 11.6988C2.16815 11.612 2.26027 11.549 2.36494 11.5192L8.49994 9.93625C8.84589 9.84706 9.16163 9.66682 9.41434 9.41429C9.66705 9.16175 9.84751 8.84614 9.93694 8.50025L11.5189 2.36525C11.5483 2.26017 11.6113 2.16759 11.6983 2.10164C11.7852 2.0357 11.8913 2 12.0004 2C12.1096 2 12.2157 2.0357 12.3026 2.10164C12.3896 2.16759 12.4525 2.26017 12.4819 2.36525L14.0629 8.50025C14.1522 8.84632 14.3326 9.16215 14.5853 9.41487C14.838 9.66759 15.1539 9.84797 15.4999 9.93725L21.6349 11.5182C21.7404 11.5473 21.8335 11.6103 21.8998 11.6973C21.9661 11.7844 22.002 11.8908 22.002 12.0002C22.002 12.1097 21.9661 12.2161 21.8998 12.3032C21.8335 12.3902 21.7404 12.4531 21.6349 12.4822L15.4999 14.0632C15.1539 14.1525 14.838 14.3329 14.5853 14.5856C14.3326 14.8384 14.1522 15.1542 14.0629 15.5002L12.4809 21.6353C12.4515 21.7403 12.3886 21.8329 12.3016 21.8989C12.2147 21.9648 12.1086 22.0005 11.9994 22.0005C11.8903 22.0005 11.7842 21.9648 11.6973 21.8989C11.6103 21.8329 11.5473 21.7403 11.5179 21.6353L9.93694 15.5002Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 3V7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 5H18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 17V19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 18H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `),
    CloseX: svgToLucideIconData(`
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L6 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 6L18 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `),
    MessageCircleHeart: svgToLucideIconData(`
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.9 20C9.80858 20.9791 12.0041 21.2443 14.0909 20.7478C16.1777 20.2514 18.0186 19.0259 19.2818 17.2922C20.545 15.5586 21.1474 13.4308 20.9806 11.2922C20.8137 9.15366 19.8886 7.14502 18.3718 5.62824C16.855 4.11146 14.8464 3.1863 12.7078 3.01946C10.5693 2.85263 8.44147 3.45509 6.70782 4.71829C4.97417 5.98149 3.74869 7.82236 3.25222 9.90916C2.75575 11.996 3.02094 14.1915 4 16.1L2 22L7.9 20Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.8001 9.19999C15.3328 8.74193 14.7045 8.48535 14.0501 8.48535C13.3958 8.48535 12.7675 8.74193 12.3001 9.19999L12.0001 9.59999L11.6501 9.29999C11.4209 9.05186 11.1422 8.85447 10.8321 8.72054C10.5219 8.5866 10.1872 8.51908 9.84938 8.52233C9.51157 8.52557 9.17818 8.59951 8.87067 8.73938C8.56316 8.87926 8.28835 9.08196 8.06391 9.33446C7.83947 9.58695 7.67038 9.88364 7.56753 10.2054C7.46467 10.5272 7.43033 10.867 7.46671 11.2028C7.50309 11.5387 7.60939 11.8632 7.77877 12.1555C7.94814 12.4478 8.17684 12.7014 8.45014 12.9L12.0501 16.4L15.6501 12.9C16.8501 11.7 16.7501 10.2 15.8501 9.19999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `),
    CircleX: svgToLucideIconData(`
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 9L9 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 9L15 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
    `)
  };
}

export const customIcons = getCustomIconList();
