// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin-bottom: var(--spacing-12);
}

.payment-widget {
  border-radius: var(--radius-4);
  background-color: var(--color-core-brand-3-2);
  padding: var(--spacing-5);
  text-align: left;
}
.payment-widget-content {
  display: flex;
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}
.payment-widget-content > div {
  flex: 1 1 auto;
}
.payment-widget-content > div p:first-child {
  margin-bottom: var(--spacing-2);
}
.payment-widget-content > div p:last-child {
  margin-bottom: var(--spacing-0);
}

app-ds-button.web-app {
  display: block;
  margin-left: var(--spacing-12);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
