import { Component, OnDestroy } from '@angular/core';
import { Customer } from '@inyova/models';
import { LoadingController, ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as fromAccount from '@account/account.reducers';

import { Languages } from '@app/app.constants';
import { State } from '@shared/models/State';
import { LanguageService } from '@shared/services/language.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-change-language',
  styleUrls: ['./account-change-language.component.scss'],
  templateUrl: './account-change-language.component.html'
})
export class AccountChangeLanguageComponent implements OnDestroy {
  Languages = Languages;
  customer: Customer;
  selectedLanguage: Languages;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private languageService: LanguageService,
    private loadingController: LoadingController,
    private store: Store<State>,
    private trackingService: TrackingService
  ) {
    this.languageService.selected.pipe(takeUntil(this.onDestroy$)).subscribe((value) => (this.selectedLanguage = value));
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((customer) => (this.customer = customer));
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async selectLanguage(language: Languages) {
    this.trackingService.trackActivity('[Radio button] Select language: ' + language);
    const loading = await this.loadingController.create({});
    await loading.present();
    this.store.dispatch(AccountActions.changeLanguage({ language: language }));
  }
}
