import { AccountAddressModalComponent } from './account-address-modal/account-address-modal.component';
import { AccountChangeLanguageComponent } from './account-change-language/account-change-language.component';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { AccountEmailModalComponent } from './account-email-modal/account-email-modal.component';
import { AccountFeesInfoComponent } from './account-fees-info/account-fees-info.component';
import { AccountFeesPromocodeComponent } from './account-fees-promocode/account-fees-promocode.component';
import { AccountGreennessModalComponent } from './account-greenness-modal/account-greenness-modal.component';
import { AccountGreennessNotificationModalComponent } from './account-greenness-notification-modal/account-greenness-notification-modal.component';
import { AccountPasswordModalComponent } from './account-password-modal/account-password-modal.component';
import { AccountPaymentModalComponent } from './account-payment-modal/account-payment-modal.component';
import { AccountPhoneModalComponent } from './account-phone-modal/account-phone-modal.component';
import { AccountVerifyPhoneNumberComponent } from './account-verify-phone-number/account-verify-phone-number.component';
import { AccountY4KModalComponent } from './account-y4k-modal/account-y4k-modal.component';

export const components = [
  AccountAddressModalComponent,
  AccountChangeLanguageComponent,
  AccountChangePasswordComponent,
  AccountEmailModalComponent,
  AccountFeesInfoComponent,
  AccountFeesPromocodeComponent,
  AccountPasswordModalComponent,
  AccountPhoneModalComponent,
  AccountVerifyPhoneNumberComponent,
  AccountY4KModalComponent,
  AccountPaymentModalComponent,
  AccountGreennessModalComponent,
  AccountGreennessNotificationModalComponent
];

export { AccountAddressModalComponent } from './account-address-modal/account-address-modal.component';
export { AccountChangeLanguageComponent } from './account-change-language/account-change-language.component';
export { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
export { AccountEmailModalComponent } from './account-email-modal/account-email-modal.component';
export { AccountFeesInfoComponent } from './account-fees-info/account-fees-info.component';
export { AccountFeesPromocodeComponent } from './account-fees-promocode/account-fees-promocode.component';
export { AccountPasswordModalComponent } from './account-password-modal/account-password-modal.component';
export { AccountPhoneModalComponent } from './account-phone-modal/account-phone-modal.component';
export { AccountVerifyPhoneNumberComponent } from './account-verify-phone-number/account-verify-phone-number.component';
export { AccountY4KModalComponent } from './account-y4k-modal/account-y4k-modal.component';
export { AccountPaymentModalComponent } from './account-payment-modal/account-payment-modal.component';
export { AccountGreennessModalComponent } from './account-greenness-modal/account-greenness-modal.component';
export { AccountGreennessNotificationModalComponent } from './account-greenness-notification-modal/account-greenness-notification-modal.component';
