import { DsAllocationBarComponent } from './ds-allocation-bar/ds-allocation-bar.component';
import { DsBadgeComponent } from './ds-badge/ds-badge.component';
import { DsButtonComponent } from './ds-button/ds-button.component';
import { DsCalloutComponent } from './ds-callout/ds-callout.component';
import { DsCheckboxComponent } from './ds-checkbox/ds-checkbox.component';
import { DsChipComponent } from './ds-chip/ds-chip.component';
import { DSContentSwitcherComponent } from './ds-content-switcher/ds-content-switcher.component';
import { DsFeedbackLoopComponent } from './ds-feedback-loop/ds-feedback-loop.component';
import { DSHeaderStepperComponent } from './ds-header-stepper/ds-header-stepper.component';
import { DsIconComponent } from './ds-icon/ds-icon.component';
import { DsInfoRowComponent } from './ds-info-row/ds-info-row.component';
import { DSLegendComponent } from './ds-legend/ds-legend.component';
import { DsLinkComponent } from './ds-link/ds-link.component';
import { DsProgressBarComponent } from './ds-progress-bar/ds-progress-bar.component';
import { DsRadioOptionComponent } from './ds-radio-option/ds-radio-option.component';
import { DsReturnsComponent } from './ds-returns/ds-returns.component';
import { DsRoundedIconComponent } from './ds-rounded-icon/ds-rounded-icon.component';
import { DsScaleComponent } from './ds-scale/ds-scale.component';
import { DsSelectComponent } from './ds-select/ds-select.component';
import { DsSliderComponent } from './ds-slider/ds-slider.component';
import { DSTabNavComponent } from './ds-tab-nav/ds-tab-nav.component';

export const components = [
  DsAllocationBarComponent,
  DsBadgeComponent,
  DSContentSwitcherComponent,
  DSLegendComponent,
  DsProgressBarComponent,
  DSTabNavComponent,
  DsReturnsComponent,
  DsRadioOptionComponent,
  DsRoundedIconComponent,
  DsFeedbackLoopComponent,
  DsSliderComponent,
  DsChipComponent,
  DsCheckboxComponent,
  DsInfoRowComponent,
  DSHeaderStepperComponent,
  DsIconComponent,
  DsLinkComponent,
  DsCalloutComponent,
  DsButtonComponent,
  DsScaleComponent,
  DsSelectComponent
];

export { DsAllocationBarComponent } from './ds-allocation-bar/ds-allocation-bar.component';
export { DsBadgeComponent } from './ds-badge/ds-badge.component';
export { DsCheckboxComponent } from './ds-checkbox/ds-checkbox.component';
export { DsChipComponent } from './ds-chip/ds-chip.component';
export { DSContentSwitcherComponent } from './ds-content-switcher/ds-content-switcher.component';
export { DsFeedbackLoopComponent } from './ds-feedback-loop/ds-feedback-loop.component';
export { DsInfoRowComponent } from './ds-info-row/ds-info-row.component';
export { DSLegendComponent } from './ds-legend/ds-legend.component';
export { DsProgressBarComponent } from './ds-progress-bar/ds-progress-bar.component';
export { DsRadioOptionComponent } from './ds-radio-option/ds-radio-option.component';
export { DsReturnsComponent } from './ds-returns/ds-returns.component';
export { DsRoundedIconComponent } from './ds-rounded-icon/ds-rounded-icon.component';
export { DsSliderComponent } from './ds-slider/ds-slider.component';
export { DSTabNavComponent } from './ds-tab-nav/ds-tab-nav.component';
export { DSHeaderStepperComponent } from './ds-header-stepper/ds-header-stepper.component';
export { DsIconComponent } from './ds-icon/ds-icon.component';
export { DsLinkComponent } from './ds-link/ds-link.component';
export { DsCalloutComponent } from './ds-callout/ds-callout.component';
export { DsButtonComponent } from './ds-button/ds-button.component';
export { DsScaleComponent } from './ds-scale/ds-scale.component';
export { DsSelectComponent } from './ds-select/ds-select.component';
