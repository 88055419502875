// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  text-align: center;
  color: var(--ion-color-primary);
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
}

ion-item.ion-item__new-password {
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
  --border-color: transparent;
}
ion-item.ion-item__new-password.ion-touched {
  --highlight-color-invalid: transparent;
}
ion-item.ion-item__new-password ion-label {
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-danger);
}
ion-item ion-icon {
  transform: translate(-2px, 25px);
  font-size: 20px;
}

ion-input.ion-new-password {
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
  --border-color: transparent;
}
ion-input.ion-new-password.ion-touched {
  --highlight-color-invalid: transparent;
}

.ion-input__errors {
  margin-bottom: 15px;
}
.ion-input__errors:last-of-type {
  margin-bottom: 30px;
}
.ion-input__errors span {
  display: none;
}
.ion-input__errors span:first-of-type {
  display: inline;
}
.ion-input__errors.ion-input__errors--valid span {
  color: var(--ion-color-primary);
}

.password-tips {
  margin-bottom: 10px;
}
.password-tips p,
.password-tips li {
  font-size: 12px;
  margin: 4px 0;
  text-align: start;
}
.password-tips ul {
  list-style-type: none;
  text-align: start;
  padding: 0;
  margin: 0;
}
.password-tips li {
  display: flex;
  align-items: center;
}
.password-tips li .password-tips__dot {
  width: 6px;
  height: 6px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 4px;
}
.password-tips li ion-icon {
  margin-right: 8px;
  display: none;
}
.password-tips li.check ion-icon {
  display: inline-block;
}
.password-tips li.check .password-tips__dot {
  display: none;
}

.security-note {
  text-align: left;
  margin-bottom: 10px;
}

ion-button:last-of-type {
  margin-top: 15px;
}

ion-progress-bar {
  --buffer-background: var(--ion-color-tertiary);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
