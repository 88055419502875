import { IBANItem, PortfolioAllocation, WithdrawalAllocationBar, WithdrawalRequestStatus, WithdrawRequest, WithdrawType } from '../models';

export const withdrawalRequestListMock: { data: WithdrawRequest[] } = {
  data: [
    {
      id: '16',
      type: 'new_funds_withdrawal_requests',
      attributes: {
        account_id: '22207',
        type: WithdrawType.PARTIAL,
        iban: 'DE75512108001245126199',
        new_reference_account_requested: true,
        amount_to_deduct: 100.0,
        close_account: null,
        status: WithdrawalRequestStatus.IN_PROGRESS
      }
    }
  ]
};

export const ibanResponseMock: { data: IBANItem[]; meta: [] } = {
  data: [
    {
      id: '4f766ac1-4d2c-4890-83ff-dda07f0cbd0c',
      type: 'beneficiaries',
      attributes: {
        bank: 'UBS SWITZERLAND AG',
        iban: 'CH444544025555754854V'
      }
    },
    {
      id: 'bd2be3cc-52f5-4840-bc8d-d4c6738e3ef8',
      type: 'beneficiaries',
      attributes: {
        bank: 'CREDIT SUISSE (SCHWEIZ) AG',
        iban: 'CH4999440295557549999'
      }
    }
  ],
  meta: []
};

export const withdrawalReasonsMock = {
  id: 1072,
  question_text: 'Withdrawal Reason Form',
  type: 'multichoice',
  subtype: null,
  hint: null,
  inyova_id: 20,
  survey_answer_options: [
    {
      id: 1183,
      option_text: 'I need cash',
      details: 'external',
      weight: null,
      score: null,
      inyova_id: 50
    },
    {
      id: 1184,
      option_text: 'Inyova is too expensive',
      details: 'internal',
      weight: null,
      score: null,
      inyova_id: 51
    },
    {
      id: 1185,
      option_text: 'Moving to a competitor',
      details: 'internal',
      weight: null,
      score: null,
      inyova_id: 52
    },
    {
      id: 1186,
      option_text: 'Not happy with returns',
      details: 'internal',
      weight: null,
      score: null,
      inyova_id: 53
    },
    {
      id: 1187,
      option_text: 'Scary about market conditions',
      details: 'external',
      weight: null,
      score: null,
      inyova_id: 54
    },
    {
      id: 1188,
      option_text: 'Alternative investment opportunities',
      details: 'external',
      weight: null,
      score: null,
      inyova_id: 55
    },
    {
      id: 1189,
      option_text: 'Need to pay taxes',
      details: 'external',
      weight: null,
      score: null,
      inyova_id: 56
    },
    {
      id: 1190,
      option_text: 'Lifestyle changes (buy home, travelling)',
      details: 'external',
      weight: null,
      score: null,
      inyova_id: 57
    }
  ],
  survey_customer_answer: null
};

export const portfolioAllocationMock: PortfolioAllocation = {
  total_value: 7128.44,
  cash_value: 300,
  stocks: {
    percentage: 0,
    value: 0
  },
  stability: {
    percentage: 0,
    value: 0
  },
  cash: {
    percentage: 0.04,
    value: 304
  },
  bonds: {
    percentage: 0,
    value: 0
  }
};

export const withdrawalAllocationBarMock: WithdrawalAllocationBar = {
  initial: {
    percentage: 0.28,
    value: 2000
  },
  portfolio: {
    percentage: 1,
    value: 4824.44
  },
  cash: {
    percentage: 0.04,
    value: 304
  }
};

export const WITHDRAWAL_PROMO_CODE = '4d359458e1';
