// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  text-align: center;
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
}

ion-item {
  margin-top: 30px;
}
ion-item ion-icon {
  transform: translate(-2px, 23px);
  font-size: 20px;
}

.ion-input__errors {
  margin-bottom: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
