// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.greenness-intro {
  margin-bottom: var(--spacing-4);
}
@media screen and (min-width: 500px) {
  .greenness-intro.web-app {
    margin-bottom: var(--spacing-6);
  }
}
.greenness-intro h1 {
  margin-top: 0;
  margin-bottom: var(--spacing-3);
}
.greenness-intro p {
  margin-bottom: var(--spacing-0);
}
.greenness-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-3);
}
.greenness-header.opened {
  margin-bottom: var(--spacing-1);
}
.greenness-header h2 {
  margin: var(--spacing-0);
}
.greenness-question {
  margin-bottom: var(--spacing-6);
}
.greenness-question > h2 {
  margin-bottom: var(--spacing-3);
}
.greenness-question > p {
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-5);
}
.greenness-action {
  margin-bottom: var(--spacing-0);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
