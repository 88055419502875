import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-y4k-modal',
  styleUrls: ['./account-y4k-modal.component.scss'],
  templateUrl: './account-y4k-modal.component.html'
})
export class AccountY4KModalComponent {
  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
