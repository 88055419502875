import { WITHDRAWAL_PROMO_CODE } from '@inyova/mocks';
import { Customer, CustomerAccount, CustomerUnifiedAccountItem } from '@inyova/models';
import { isMinProspect } from '@inyova/utils';
import * as dayjs from 'dayjs';

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as AccountActions from './account.actions';

import { MAXIMUM_3A_CONTRIBUTION } from '@app/app.constants';
import {
  ABTest,
  AccountDocument,
  Address,
  Documents3AStore,
  FinancialSituation,
  GrowDocument,
  ProfileBannerItem,
  Promotions,
  StaticDocument,
  Transaction,
  TransactionsView
} from '@shared/models/Account';
import { State } from '@shared/models/State';

export const ACCOUNT_REDUCER_FEATURE_KEY = 'account';

export const initialState: AccountState = {
  switchAccountLoading: {
    show: false,
    message: ''
  },
  address: {
    city: '',
    country: '',
    number: '',
    street: '',
    zip: ''
  },
  customer: {
    interview_widget: false,
    inyova_pro: false,
    superhuman_visible: false,
    accounts: [],
    unified_accounts: [],
    total_current_amount: 0,
    app_location: 'ch',
    created_at: '',
    email: '',
    first_name: '',
    id: 0,
    inyova_id: 0,
    language: 'de-CH',
    last_name: '',
    lead_source: '',
    otp_module: 'disabled',
    phone: '',
    verified_phone: '',
    unverified_phone: '',
    provider: '',
    source_type_id: 3,
    tag_manager: { origin: '', first_time: false },
    is_friend: false,
    is_test: false,
    referral_code: '',
    hide_personalisation: false,
    total_referrals: {
      free_feedays: 0,
      my_referral_customer: '',
      referrallimit: false
    },
    test_version: '',
    track_id: '',
    uid: '',
    has_crowd_investor_account: false,
    has_interest_account: false,
    saxo_data_renewal_expires_at: null,
    saxo_bank_onboarding_done: false,
    show_interest_account_banner: false,
    show_greenness_survey: null
  },
  currentAccount: {
    id: '',
    owner_name: '',
    step_status: 0,
    onboarding_step: 0,
    kind: '3b',
    edit_mode: false,
    strategy_edit_mode_disabled_at: null,
    strategy_edit_mode_enabled_at: null,
    on_hold: false,
    active: true,
    created_at: '',
    product_type: '',
    initial_investment: '',
    current_amount: 0,
    currency: 'CHF',
    customer_journey: {
      account_data_filled: true,
      account_opened: true,
      account_funded: true,
      customer_funded: true,
      compliance_done: true,
      id_verification_completed: true,
      risk_checked: true,
      ql_flow_completed: true
    },
    iban: '',
    is_yova_yellow: false,
    first_time_yellow: false,
    max_contribution_level: 'small',
    minimum_investment: 0,
    deposit_this_year: 0,
    liberty_account: {
      qr_iban: '',
      reference_number: ''
    },
    savings_plan_social_proof: null,
    is_wisher: null,
    liquidation_only: null,
    has_low_investment_balance: false,
    next_strategy_change_on: '',
    inyova_fee: 0,
    ql_onboarding_step: 0,
    risk_recheck_visible: false,
    tutorial_seen: false
  },
  customerDocuments: {
    initialized: false,
    documents: []
  },
  documents3A: {
    initialized: false,
    documents: [],
    pagination: {
      total: 0
    }
  },
  documentsGrow: {
    initialized: false,
    documents: []
  },
  staticDocuments: {
    initialized: false,
    documents: []
  },
  promotions: {
    data: [],
    included: [],
    meta: {
      active_rewards: {
        trees_planted: 0,
        months: 0,
        money_monthly: 0
      },
      pending_rewards: {
        months: 0,
        money_overall: 0
      },
      referral_rewards: {
        months: 0,
        money: {
          money_monthly: 0,
          months_duration: 0
        }
      }
    }
  },
  transactions: {},
  transactionsInitialized: false,
  ABTests: [],
  profileBanners: [],
  financialSituation: null
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.getCustomerDocumentsSuccess, (state, action): AccountState => {
    return {
      ...state,
      customerDocuments: {
        initialized: true,
        documents: action.documents
      }
    };
  }),
  on(AccountActions.getCustomerDocumentsFail, (state): AccountState => {
    return {
      ...state,
      customerDocuments: {
        initialized: true,
        documents: []
      }
    };
  }),
  on(
    AccountActions.getDocuments3ASuccess,
    (state, action): AccountState => ({
      ...state,
      documents3A: {
        ...state.documents3A,
        initialized: true,
        documents: [...state.documents3A.documents, ...action.documents],
        pagination: { total: action.meta.stats?.total.count ?? 0 }
      }
    })
  ),
  on(
    AccountActions.getDocuments3AFail,
    (state): AccountState => ({
      ...state,
      documents3A: {
        ...state.documents3A,
        initialized: true,
        documents: []
      }
    })
  ),
  on(
    AccountActions.getStaticDocumentsSuccess,
    (state, action): AccountState => ({
      ...state,
      staticDocuments: {
        initialized: true,
        documents: action.documents
      }
    })
  ),
  on(
    AccountActions.getStaticDocumentsFail,
    (state): AccountState => ({
      ...state,
      staticDocuments: {
        initialized: true,
        documents: []
      }
    })
  ),
  on(AccountActions.getDocumentsGrowSuccess, (state, action): AccountState => {
    return {
      ...state,
      documentsGrow: {
        initialized: true,
        documents: action.documents
      }
    };
  }),
  on(AccountActions.getDocumentsGrowFail, (state): AccountState => {
    return {
      ...state,
      documentsGrow: {
        initialized: true,
        documents: []
      }
    };
  }),
  on(
    AccountActions.getTransactionsSuccess,
    (state, action): AccountState => ({
      ...state,
      transactions: action.data,
      transactionsInitialized: true
    })
  ),
  on(
    AccountActions.getPromotionsSuccess,
    (state, action): AccountState => ({
      ...state,
      promotions: action.data
    })
  ),
  on(
    AccountActions.updateCurrentAccount,
    (state, action): AccountState => ({
      ...state,
      currentAccount: {
        ...state.currentAccount,
        ...action.data
      }
    })
  ),
  on(
    AccountActions.setCurrentAccount,
    (state, action): AccountState => ({
      ...state,
      currentAccount: action.data,
      transactionsInitialized: false
    })
  ),
  on(
    AccountActions.setCurrentAccountID,
    (state, action): AccountState => ({
      ...state,
      currentAccount: {
        ...state.currentAccount,
        id: action.id
      }
    })
  ),
  on(
    AccountActions.setAccountDetails,
    (state, action): AccountState => ({
      ...state,
      address: {
        city: action.data.city,
        country: action.data.country,
        number: action.data.number,
        street: action.data.street,
        zip: action.data.zip
      }
    })
  ),
  // Remove app_location definition, after API starts return actual location
  on(
    AccountActions.setCustomer,
    (state, action): AccountState => ({
      ...state,
      customer: action.data
    })
  ),
  on(
    AccountActions.setSwitchAccountLoading,
    (state, action): AccountState => ({
      ...state,
      switchAccountLoading: {
        show: action.show,
        message: action.message
      }
    })
  ),
  on(
    AccountActions.changeLanguage,
    (state): AccountState => ({
      ...state,
      transactionsInitialized: false
    })
  ),
  on(
    AccountActions.getABTestsSuccess,
    (state, action): AccountState => ({
      ...state,
      ABTests: action.data
    })
  ),
  on(
    AccountActions.getProfileBannersSuccess,
    (state, action): AccountState => ({
      ...state,
      profileBanners: action.data
    })
  ),
  on(
    AccountActions.getProfileBannersFail,
    (state): AccountState => ({
      ...state,
      profileBanners: []
    })
  ),
  on(
    AccountActions.getFinancialSituationSuccess,
    (state, action): AccountState => ({
      ...state,
      financialSituation: action.data
    })
  ),
  on(
    AccountActions.getFinancialSituationFail,
    (state): AccountState => ({
      ...state,
      financialSituation: null
    })
  ),
  on(AccountActions.setInyovaGrowAccountFlags, (state, action): AccountState => {
    const interestAccount: CustomerUnifiedAccountItem | undefined = state.customer.unified_accounts.find(
      (accountItem: CustomerUnifiedAccountItem) => accountItem.id === action.growAccountId
    );

    let unified_accounts = [...state.customer.unified_accounts];

    if (typeof interestAccount === 'undefined') {
      unified_accounts = state.customer.unified_accounts.concat([
        {
          id: action.growAccountId,
          kind: 'interest',
          owner_name: '',
          account_currency: 'CHF',
          step_status: 'opened'
        } as CustomerUnifiedAccountItem
      ]);
    } else {
      if (interestAccount.step_status === 'initial') {
        unified_accounts = state.customer.unified_accounts.map((accountItem: CustomerUnifiedAccountItem) => {
          if (accountItem.kind === 'interest') {
            return {
              ...accountItem,
              step_status: 'opened'
            } as CustomerUnifiedAccountItem;
          }
          return accountItem;
        });
      }
    }

    return {
      ...state,
      customer: {
        ...state.customer,
        has_interest_account: action.has_interest_account,
        show_interest_account_banner: action.show_interest_account_banner,
        unified_accounts
      }
    };
  }),
  on(AccountActions.submitGreennessSurveySuccess, (state) => ({
    ...state,
    customer: {
      ...state.customer,
      show_greenness_survey: null
    }
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogout, (): AccountState => ({ ...initialState })),
  on(AccountActions.customerLogoutAndUnpairDevice, (): AccountState => ({ ...initialState }))
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action);
}

export interface AccountState {
  switchAccountLoading: {
    show: boolean;
    message: string;
  };
  address: Address;
  customer: Customer;
  currentAccount: CustomerAccount;
  customerDocuments: {
    initialized: boolean;
    documents: AccountDocument[];
  };
  documents3A: Documents3AStore;
  documentsGrow: {
    initialized: boolean;
    documents: GrowDocument[];
  };
  staticDocuments: {
    initialized: boolean;
    documents: StaticDocument[];
  };
  promotions: Promotions;
  transactions: { [key: string]: Transaction[] };
  transactionsInitialized: boolean;
  ABTests: ABTest[];
  profileBanners: ProfileBannerItem[];
  financialSituation: FinancialSituation;
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.account;
// Account reducer selectors
export const selectCustomer = createSelector(selectFeature, (state: AccountState) =>
  state && state.customer ? state.customer : initialState.customer
);
export const selectHas3AAccountMinProspect = createSelector(selectCustomer, (state: Customer) =>
  state.accounts.some((item) => item.kind === '3a' && isMinProspect(item.step_status))
);
export const selectCurrentAccount = createSelector(selectFeature, (state: AccountState) => {
  if (!state) {
    return initialState.currentAccount;
  }
  return state.currentAccount;
});
export const selectCurrentAccountID = createSelector(selectFeature, (state: AccountState) => state && state.currentAccount.id);
export const selectCustomerAndCurrentAccount = createSelector(selectCustomer, selectCurrentAccount, (customer, currentAccount) => {
  return { customer, currentAccount };
});
export const selectDocuments3A = createSelector(selectFeature, (state: AccountState) => state.documents3A);
export const selectDocuments3APaged = createSelector(selectDocuments3A, (state: Documents3AStore) => {
  if (!state || !state.documents || !state.documents.length) {
    return {
      documents: [],
      totalNumber: 0,
      initialized: state.initialized
    };
  }

  return {
    documents: state.documents,
    totalNumber: state.pagination.total,
    initialized: state.initialized
  };
});

export const selectGeneralDocuments = createSelector(selectFeature, (state: AccountState) => {
  const { initialized, documents } = state.customerDocuments;

  const accountSpecificDocIDs = [5, 22, 23, 24, 42, 43];
  const docs = documents
    .filter((doc) => !accountSpecificDocIDs.includes(doc.attributes.type_id))
    .sort((a, b) => b.attributes.type_id - a.attributes.type_id);
  return { initialized, docs };
});

export const selectDocumentsCI = createSelector(selectFeature, (state: AccountState) => {
  const { initialized, documents } = state.customerDocuments;

  const docs = documents.filter((doc) => [42, 43].includes(doc.attributes.type_id)).sort((a, b) => a.attributes.type_id - b.attributes.type_id);

  return { initialized, docs };
});

export const selectDocuments3B = createSelector(selectFeature, (state: AccountState) => {
  const { initialized } = state.customerDocuments;

  const reportingDocs = [];
  const taxDocs = [];

  state.customerDocuments.documents.forEach((doc) => {
    if (doc.attributes.type_id === 22) {
      reportingDocs.push(doc);
    } else if (doc.attributes.type_id === 5) {
      taxDocs.push(doc);
    }
  });

  return { initialized, taxDocs, reportingDocs };
});

export const selectDocumentsGrow = createSelector(selectFeature, (state: AccountState) => {
  const { initialized, documents } = state.documentsGrow;

  const groupedDocuments: { [key: string]: { title: string; documents: GrowDocument[] } } = documents.reduce(function (
    obj: { [key: string]: { title: string; documents: GrowDocument[] } },
    document
  ) {
    if (typeof obj[document.project_id] === 'undefined') {
      obj[document.project_id] = { title: document.project_title, documents: [document] };
      return obj;
    }
    obj[document.project_id].documents.push(document);
    return obj;
  }, {});

  const projects = Object.keys(groupedDocuments).map((key) => ({
    title: groupedDocuments[key].title,
    documents: groupedDocuments[key].documents
  }));
  return { initialized, projects };
});

export const selectTransfer3ADocument = createSelector(selectFeature, (state: AccountState) => {
  if (!state || !state.customerDocuments || !state.customerDocuments.initialized) return [];
  return state.customerDocuments.documents.filter((doc) => doc.attributes.type_id === 24);
});

export const selectStaticDocuments = createSelector(selectFeature, (state: AccountState) => {
  const { initialized } = state.staticDocuments;
  const docs = state.staticDocuments.documents;

  let static3aDocs: StaticDocument[] = [];
  let staticGeneralDocs: StaticDocument[] = [];
  let agmDocs: StaticDocument[] = [];
  let egmDocs: StaticDocument[] = [];
  let CIUpdates: StaticDocument[] = [];

  for (const doc of docs) {
    switch (doc.attributes.account_type) {
      case 'crowd_investing':
        switch (doc.attributes.category) {
          case 'agm':
            agmDocs = [...agmDocs, doc];
            break;
          case 'egm':
            egmDocs = [...egmDocs, doc];
            break;
          case 'updates':
            CIUpdates = [...CIUpdates, doc];
            break;
          default:
            break;
        }
        break;
      case '3a':
        static3aDocs = [...static3aDocs, doc];
        break;
      case '3b':
      case 'grow':
        staticGeneralDocs = [...staticGeneralDocs, doc];
        break;
      default:
        break;
    }
  }

  return { initialized, CIUpdates, agmDocs, egmDocs, static3aDocs, staticGeneralDocs };
});

export const selectAllDocuments = createSelector(
  selectGeneralDocuments,
  selectDocuments3B,
  selectDocumentsGrow,
  selectDocuments3APaged,
  selectStaticDocuments,
  selectDocumentsCI,
  (docsGeneral, docs3b, docsGrow, docs3a, docsStatic, docsCI) => {
    return {
      docsGeneral,
      docs3b,
      docsGrow,
      docs3a,
      docsStatic,
      docsCI
    };
  }
);

export const selectTransactions = createSelector(selectFeature, (state: AccountState) => state.transactions);
export const selectTransactionsInitialized = createSelector(selectFeature, (state: AccountState) => state.transactionsInitialized);

export const selectTransactionsView = createSelector(
  selectCustomerAndCurrentAccount,
  selectTransactionsInitialized,
  selectTransactions,
  ({ customer, currentAccount }, loaded, transactions) => {
    const currentYear = new Date().getFullYear();

    return {
      appLocation: customer.app_location,
      currency: currentAccount.currency,
      depositThisYear: currentAccount.deposit_this_year,
      ownerName: currentAccount.owner_name,
      kind: currentAccount.kind,
      maxContributionLevel: currentAccount.max_contribution_level,
      loaded,
      transactions,
      inyovaFee: currentAccount.inyova_fee,
      currentYear,
      possibleYearlyDeposit: currentAccount.kind === '3a' ? MAXIMUM_3A_CONTRIBUTION[currentYear][currentAccount.max_contribution_level] : 0
    } as TransactionsView;
  }
);

export const selectPromotions = createSelector(selectFeature, (state: AccountState) => state.promotions);
export const selectHasActivePromotions = createSelector(selectFeature, (state: AccountState) => !!state?.promotions?.included);
export const selectPromotionsDataForRedeemCode = createSelector(selectPromotions, (state: Promotions) => {
  const defaults = {
    code: '',
    activatedAt: '',
    freeMonths: 0,
    moneyPrmotion: {
      amount: 0,
      period: 0
    }
  };

  if (!state.included || !state.included?.length) {
    return defaults;
  }

  const attributes = state.included[0]?.attributes;

  defaults.code = attributes.code;
  defaults.activatedAt = state.data[0]?.attributes?.valid_from;

  if (attributes.type === 'MoneyPromotion') {
    defaults.moneyPrmotion.amount = attributes.value;
    defaults.moneyPrmotion.period = Number(attributes.months_duration);
  }

  if (attributes.type === 'FreeMonthsPromotion') {
    defaults.freeMonths = Number(attributes.months_duration);
  }

  return defaults;
});
export const selectNeonPromotion = createSelector(selectPromotions, (state: Promotions) => {
  if (!state || !state.included || state.included.length === 0) {
    return false;
  }
  const neonPromotion = state.included.find((promotion) => promotion.attributes.code === 'neon3a');
  if (!neonPromotion) {
    return false;
  }
  const neonValidityDate = state.data.find((promotion) => Number(promotion.relationships.promotion.data.id) === Number(neonPromotion.id)).attributes
    .valid_from;
  const dayDifference = dayjs().diff(dayjs(neonValidityDate, 'YYYY-MM-DD'), 'day');
  return dayDifference < 60;
});

export const selectWithdrawalPromotion = createSelector(selectPromotions, (promotions: Promotions) => {
  if (!promotions || !promotions.included || promotions.included.length === 0) {
    return false;
  }

  const notForReferMonthsSum = promotions.included
    .filter((promotion) => promotion.attributes.for_referring === false)
    .reduce((acc, next) => {
      return acc + parseInt(next.attributes.months_duration);
    }, 0);

  if (notForReferMonthsSum > 9) {
    // do not show WDL promo step
    return true;
  }

  return !!promotions.included.find((promotion) => promotion.attributes.code === WITHDRAWAL_PROMO_CODE);
});

export const selectMultipleActivePromotions = createSelector(
  selectPromotions,
  selectCurrentAccount,
  selectNeonPromotion,
  (state: Promotions, currentAccount, neonPromotionActive) => {
    if (!state || !state.meta || !state.meta.active_rewards) {
      return false;
    }

    const multipleActivePromotions = Object.keys(state.meta.active_rewards).filter((key) => state.meta.active_rewards[key] !== 0).length;

    if (multipleActivePromotions > 1 || (multipleActivePromotions === 1 && neonPromotionActive && currentAccount.kind === '3a')) {
      return true;
    }

    return false;
  }
);
export const selectAccountDetails = createSelector(selectFeature, (state: AccountState) => {
  if (!state) return initialState.address;
  return state.address;
});
export const selectIsAccountLoading = createSelector(selectFeature, (state: AccountState) => {
  if (!state) return initialState.switchAccountLoading;
  return state.switchAccountLoading;
});

export const selectActiveABTest = (experimentID: string) =>
  createSelector(selectFeature, (state: AccountState) => {
    const concreteABTest = state.ABTests.find((element) => element.attributes.experiment_id === experimentID);
    return concreteABTest?.attributes.alternative === 'false' ? false : concreteABTest?.attributes.alternative;
  });

export const selectProfileBanners = createSelector(selectFeature, (state: AccountState) => {
  return state.profileBanners;
});

export const selectFinancialSituation = createSelector(selectFeature, (state: AccountState) => {
  return state.financialSituation;
});

export const selectSavingsPlanSocialProof = createSelector(selectFeature, (state: AccountState) => state.currentAccount.savings_plan_social_proof);

export const selectHasGrowAccount = createSelector(selectCustomer, (customer: Customer) => customer.has_interest_account);
