/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer, CustomerAccount, InyovaGrowAccountStatus, StepStatus } from '@inyova/models';
import { isMaxProspect, isMinProspect } from '@inyova/utils';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash-es';

import { select, Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as fromAccount from '@account/account.reducers';

import { Address, CustomerPersonalDetails, PostAPIZipValidation } from '@shared/models/Account';
import { State } from '@shared/models/State';
import { ToastService } from '@shared/services/toast.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-address-modal',
  styleUrls: ['./account-address-modal.component.scss'],
  templateUrl: './account-address-modal.component.html'
})
export class AccountAddressModalComponent {
  address: Address;
  addressForm: FormGroup;
  customer: Customer;
  currentAccount: CustomerAccount;
  citiesDropdown: string[];
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private router: Router,
    private store: Store<State>,
    private changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) {
    this.store
      .select(fromAccount.selectCustomer)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((customer: Customer) => {
        this.customer = customer;
      });
    this.store.pipe(select(fromAccount.selectCurrentAccount), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.currentAccount = value;
    });
    this.store.pipe(select(fromAccount.selectAccountDetails), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.address = value;
    });
    this.addressForm = this.fb.group({
      city: ['', [Validators.required]],
      country: [{ value: '', disabled: true }],
      number: ['', [Validators.required]],
      street: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    });
  }

  ionViewWillEnter(): void {
    const address = cloneDeep(this.address);
    if (address && address.id) {
      delete address.id;
    }
    this.addressForm.setValue(address);
    if (this.customer.app_location !== 'ch') {
      return;
    }
    this.citiesDropdown = [address.city];
    this.addressForm
      .get('zip')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: string) => {
        this.validateZip(value);
      });
  }

  dismiss(): void {
    this.modalController
      .dismiss({
        dismissed: true
      })
      .finally(noop);
  }

  hasError(control: string, error: string): boolean {
    if (!this.addressForm.get(control)) {
      return false;
    }
    return this.addressForm.get(control).hasError(error) && this.addressForm.get(control).touched;
  }

  // eslint-disable-next-line consistent-return
  async save() {
    if (this.addressForm.invalid) {
      return this.addressForm.markAllAsTouched();
    }
    this.trackingService.trackActivity('[Button] Update address');
    if (isEqual(this.addressForm.getRawValue(), this.address)) {
      this.dismiss();
      await this.toastService.show(this.translateService.instant('ACCOUNT.personal.flash.success'));
    } else {
      const loading = await this.loadingController.create({});
      await loading.present();
      const personalDetails: Partial<CustomerPersonalDetails> = {
        street: this.addressForm.get('street').value,
        number: this.addressForm.get('number').value,
        zip: this.addressForm.get('zip').value,
        city: this.addressForm.get('city').value
      };
      this.store.dispatch(AccountActions.updateAddress({ personalDetails }));
    }
  }

  openContactUs(): void {
    this.trackingService.trackActivity('[Link] Contact us (Address change)');
    this.dismiss();
    this.router.navigateByUrl('/tabs/account/support').finally(noop);
  }

  private validateZip(value: string) {
    if (value.length > 4 || value.length < 4) {
      this.addressForm.get('zip').setErrors({ invalidZipCode: true });
      return;
    }
    this.validateZipCodeAPI(value)
      .then((response) => {
        if (!response.records.length) {
          this.addressForm.get('city').setValue('');
          this.citiesDropdown = [];
          this.addressForm.get('zip').setErrors({ invalidZipCode: true });
          this.addressForm.get('city').disable();
          return;
        }
        if (response.records.length === 1) {
          const city = response.records[0].fields.ortbez18;
          this.addressForm.get('zip').setErrors(null);
          this.addressForm.get('city').setValue(city);
          this.addressForm.get('city').disable();
          this.citiesDropdown = [city];
          this.changeDetectorRef.detectChanges();
        } else {
          this.addressForm.get('city').enable();
          this.citiesDropdown = response.records.map((item) => item.fields.ortbez18).sort();
          this.addressForm.get('city').setValue('');
        }
        this.addressForm.get('zip').setErrors(null);
      })
      .catch(() => {
        this.addressForm.get('zip').setErrors(null);
        this.addressForm.get('city').disable();
      });
    this.addressForm.updateValueAndValidity();
  }

  private validateZipCodeAPI(zip: string): Promise<PostAPIZipValidation> {
    // vanillaJS - because of CORS issue
    return fetch(
      `https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2&q=&facet=postleitzahl&facet=ortbez18&refine.postleitzahl=${zip}`
    ).then((response) => response.json()) as Promise<PostAPIZipValidation>;
  }

  isMinProspect(status: StepStatus | InyovaGrowAccountStatus): boolean {
    return isMinProspect(status);
  }

  isMaxProspect(status: StepStatus | InyovaGrowAccountStatus): boolean {
    return isMaxProspect(status);
  }
}
