import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmplitudeService } from '@inyova/inyova-shared';
import { PaymentOptions } from '@inyova/models';

@Component({
  selector: 'app-account-payment-options',
  templateUrl: './account-payment-options.component.html',
  styleUrls: ['./account-payment-options.component.scss']
})
export class AccountPaymentOptionsComponent {
  @Input() webApp = false;
  @Output() submitOption: EventEmitter<PaymentOptions> = new EventEmitter<PaymentOptions>();

  paymentOptions: PaymentOptions[] = Object.values(PaymentOptions);

  form: FormGroup = this.fb.group({
    payment: ['', [Validators.required]]
  });

  get paymentControl(): AbstractControl {
    return this.form.get('payment');
  }

  constructor(
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService
  ) {}

  onSelectOption(value: string) {
    this.paymentControl.patchValue(value);
    this.paymentControl.updateValueAndValidity();
  }

  onSubmit(paymentOption: PaymentOptions) {
    this.amplitudeService.trackEvent('Additional Payment Options Submitted', { payment_option_name: paymentOption });
    this.submitOption.emit(paymentOption);
  }
}
