import { Component, Input } from '@angular/core';
import { GreennessSurveyTypes } from '@inyova/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-greenness-modal',
  templateUrl: './account-greenness-modal.component.html',
  styleUrls: ['./account-greenness-modal.component.scss']
})
export class AccountGreennessModalComponent {
  @Input() appLocation: string;
  @Input() showSurvey: GreennessSurveyTypes;
  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }

  onSurveySubmit(data: { score: number; income: string; worth: string }) {
    this.modalController.dismiss({
      ...(data.score && { greenness_score: data.score }),
      ...(data.income && { annual_income: data.income }),
      ...(data.worth && { net_worth: data.worth })
    });
  }
}
