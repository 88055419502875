// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

h3 {
  margin-bottom: var(--spacing-3);
}

p {
  margin-bottom: var(--spacing-6);
}

app-ds-radio-option {
  display: block;
  margin-bottom: var(--spacing-2);
}

form > app-ds-button {
  display: block;
  margin-top: var(--spacing-8);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
