import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-fees-info',
  styleUrls: ['./account-fees-info.component.scss'],
  templateUrl: './account-fees-info.component.html'
})
export class AccountFeesInfoComponent {
  @Input() selected: number;
  @Input() productType: '3b' | '3a' | 'kid';

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
