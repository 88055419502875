import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import * as AccountActions from '@account/account.actions';

import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-password-modal',
  styleUrls: ['./account-password-modal.component.scss'],
  templateUrl: './account-password-modal.component.html'
})
export class AccountPasswordModalComponent implements OnDestroy {
  @Input() location: 'email' | 'phone';
  confirmPasswordForm: FormGroup;
  passVisible: boolean;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private trackingService: TrackingService,
    private store: Store<State>
  ) {
    this.confirmPasswordForm = this.fb.group({
      password: ['', [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.confirmPasswordForm.reset();
  }

  dismiss(): void {
    this.modalController.dismiss({ dismissed: true }).finally(noop);
  }

  async continue(): Promise<void> {
    if (this.confirmPasswordForm.invalid) {
      this.confirmPasswordForm.markAllAsTouched();
      return;
    }
    this.trackingService.trackActivity('[Button] Password confirmed');
    const loading = await this.loadingController.create();
    await loading.present();
    this.store.dispatch(
      AccountActions.checkPassword({
        password: this.confirmPasswordForm.getRawValue().password,
        nextScreen: this.location,
        twoFA: false,
        enablePathActivated: false
      })
    );
  }
}
