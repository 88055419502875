// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.greenness-notification p {
  margin-top: 0;
  margin-bottom: var(--spacing-4);
}
.greenness-notification.web-app .greenness-header {
  margin-bottom: var(--spacing-4);
}
.greenness-notification.web-app p {
  margin-bottom: var(--spacing-6);
}
.greenness-header {
  display: flex;
  gap: var(--spacing-1);
  align-items: center;
  margin-bottom: var(--spacing-3);
}
.greenness-header > h1 {
  margin-top: 0;
  margin-bottom: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
