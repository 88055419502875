import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { InyovaGrowRiskGroups, InyovaGrowRiskSurveyQuestion } from '../models';

export const getRiskType = (step: number) => {
  switch (step) {
    case 0:
      return 'interest_only';
    case 1:
      return 'very_conservative';
    case 2:
      return 'conservative';
    case 3:
      return 'balanced';
    case 4:
      return 'dynamic';
    case 5:
      return 'very_dynamic';
    default:
      return 'interest_only';
  }
};

export const RISK_RATIO_TO_SLIDER_STEP = { 0: 0, 0.2: 1, 0.4: 2, 0.6: 3, 0.8: 4, 1: 5 };
export const RISK_SLIDER_STEP_TO_RATIO = { 0: 0, 1: 0.2, 2: 0.4, 3: 0.6, 4: 0.8, 5: 1 };

/**
 * @description Group questions based on type ( types are defined by UI )
 * @param riskQuestions
 * @return grouped riskQuestions
 */
export const groupRiskQuestions = (riskQuestions: InyovaGrowRiskSurveyQuestion[]): InyovaGrowRiskGroups => {
  let optionalList: InyovaGrowRiskSurveyQuestion | undefined;
  let experience: InyovaGrowRiskSurveyQuestion;
  let investHorizon: InyovaGrowRiskSurveyQuestion;
  const basic: InyovaGrowRiskSurveyQuestion[] = [];
  const optional: InyovaGrowRiskSurveyQuestion[] = [];
  const singleChoice: InyovaGrowRiskSurveyQuestion[] = [];
  const multiChoices: InyovaGrowRiskSurveyQuestion[] = [];

  for (const question of riskQuestions) {
    switch (question.inyova_id) {
      case 36:
        experience = question;
        break;
      case 37:
        optionalList = question;
        break;
      case 1:
        investHorizon = question;
        break;
      case 2:
      case 3:
      case 4:
        basic.push(question);
        break;
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
        optional.push(question);
        break;
      case 43:
      case 44:
        singleChoice.push(question);
        break;
      case 45:
        multiChoices.push(question);
        break;
    }
  }
  return {
    experience,
    investHorizon,
    basic,
    optionalList,
    optional,
    singleChoice,
    multiChoices
  };
};

export function freelyMonthlyAvailable(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const initialAmount = form.get(['investment', 'initial'])?.value;
    const freelyAmount = form.get(['assets', 'assets'])?.value;

    if (!initialAmount || !freelyAmount) {
      return null;
    }

    if (initialAmount > freelyAmount) {
      return { invalidFreely: true };
    }

    return null;
  };
}
