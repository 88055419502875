import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplitudeService } from '@inyova/inyova-shared';
import { PaymentOptions } from '@inyova/models';

@Component({
  selector: 'app-account-payment-widget',
  templateUrl: './account-payment-widget.component.html',
  styleUrls: ['./account-payment-widget.component.scss']
})
export class AccountPaymentWidgetComponent implements OnInit {
  paymentOptions = PaymentOptions;

  @Input() submitted = false;
  @Input() kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  @Input() selectedPayment: null | PaymentOptions = null;
  @Input() webApp = false;
  @Output() openPaymentModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(private amplitudeService: AmplitudeService) {}

  ngOnInit() {
    this.amplitudeService.trackEvent('Account details Opened', { kind: this.kind });
  }

  onOpenPaymentModal() {
    this.openPaymentModal.emit();
    this.amplitudeService.trackEvent('Additional Payment Options Opened', { kind: this.kind });
  }
}
