import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-ds-slider',
  templateUrl: './ds-slider.component.html',
  styleUrls: ['./ds-slider.component.scss']
})
export class DsSliderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() step!: number;
  @Input() value!: number;
  @Input() showLegends = true;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('parentHolder') parentHolder: ElementRef;

  activeStep!: number;
  legends: number[];
  legendPositions = [];

  ngOnInit() {
    // Check default values and set
    // 1 & 0 are default initial value. We should pass at least step as attribute
    this.step = this.step ?? 1;
    this.value = this.value ?? 0;
    this.activeStep = Number(this.value);
  }

  ngAfterViewInit() {
    this.setLegends();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.value.currentValue && !changes.value.firstChange) {
      this.activeStep = Number(this.value);
    }
  }

  onChangeSlider(event: Event) {
    this.activeStep = Number((event.target as HTMLInputElement).value);
    this.valueChange.emit(this.activeStep);
  }

  setLegends() {
    if (!this.showLegends) {
      return;
    }

    this.legends = [...Array(Number(this.step) - 1).keys()].map((i) => i + 1);
    this.legendPositions = this.legends.map((value) => {
      const ratio = value / Number(this.step);
      const parentWidth = this.parentHolder?.nativeElement.offsetWidth || 0;
      return ratio * parentWidth - ratio + 'px';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setLegends();
  }
}
