// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  --thumb-height: 24px;
  --thumb-width: 24px;
  --track-height: 8px;
  --legend-width: 4px;
  --legend-height: 4px;
}

.ds-slider {
  overflow: hidden;
  position: relative;
  height: var(--thumb-height);
}
.ds-slider input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none !important;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  display: block;
  position: absolute;
  height: var(--thumb-height);
  z-index: 1;
  top: 0;
  margin: 0;
}
.ds-slider input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  background: var(--color-core-brand-6-4);
  height: var(--track-height);
  border-radius: var(--radius-2);
}
.ds-slider input[type=range]::-moz-range-track {
  background: var(--color-core-brand-6-4);
  height: var(--track-height);
  border-radius: var(--radius-2);
}
.ds-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  border-radius: 100%;
  margin-top: calc(var(--track-height) / 2 - var(--thumb-height) / 2);
  background: transparent !important;
  box-shadow: none !important;
  height: var(--thumb-height);
  width: var(--thumb-width);
}
.ds-slider input[type=range]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 100%;
  background: var(--color-core-brand-6-7);
  height: var(--thumb-height);
  width: var(--thumb-width);
}
.ds-slider .progress-bar {
  height: var(--track-height);
  border-radius: var(--radius-2);
  background: var(--color-core-brand-6-7);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 2;
}
.ds-slider .thumb {
  border-radius: 100%;
  margin-top: calc(var(--track-height) / 2 - var(--thumb-height) / 2);
  background: var(--color-core-brand-6-7);
  height: var(--thumb-height);
  width: var(--thumb-width);
  position: relative;
  transform: translateX(-50%) translateY(calc(50% - var(--track-height) / 2));
  z-index: 3;
  pointer-events: none;
}
.ds-slider .thumb.first {
  transform: translateX(0) translateY(calc(50% - var(--track-height) / 2));
}
.ds-slider .thumb.last {
  transform: translateX(-100%) translateY(calc(50% - var(--track-height) / 2));
}

datalist {
  display: block;
  width: 100%;
  height: var(--track-height);
  transform: translateY(calc(var(--thumb-height) * -1));
  position: relative;
}
datalist div {
  font-size: 0;
  width: var(--legend-width);
  height: var(--legend-height);
  border-radius: 100%;
  background: var(--color-core-neutral-4);
  position: absolute;
}
datalist div:first-child {
  left: calc(var(--thumb-width) / 2 - var(--legend-width) / 2);
}
datalist div:last-child {
  left: calc(100% - var(--thumb-width) / 2 - var(--legend-width) / 2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
