import { Component } from '@angular/core';
import { PaymentOptions } from '@inyova/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-payment-modal',
  templateUrl: './account-payment-modal.component.html',
  styleUrls: ['./account-payment-modal.component.scss']
})
export class AccountPaymentModalComponent {
  constructor(private modalController: ModalController) {}

  onSubmit(selectedPayment: PaymentOptions) {
    this.modalController.dismiss({ selectedPayment });
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
